import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

function UnsubscribeCredits() {
  const { token } = useParams();
  const [status, setStatus] = useState('Processing...');

  useEffect(() => {
    const unsubscribe = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/unsubscribe-credits/${token}`);
        setStatus('You have been successfully unsubscribed from credit notifications.');
      } catch (error) {
        setStatus('Failed to unsubscribe. The link may be invalid or expired.');
      }
    };

    unsubscribe();
  }, [token]);

  return (
    <div className="card">
      <h2>Unsubscribe from Credit Notifications</h2>
      <p>{status}</p>
    </div>
  );
}

export default UnsubscribeCredits; 