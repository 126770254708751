import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

function NotificationSettings() {
  const { user } = useAuth();
  const [threshold, setThreshold] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchThreshold = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/auth/credit-threshold/${user.id}`);
        setThreshold(response.data.threshold || '');
      } catch (error) {
        console.error('Error fetching threshold:', error);
      }
    };
    fetchThreshold();
  }, [user.id]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/auth/credit-threshold`, {
        userId: user.id,
        threshold: threshold === '' ? null : parseInt(threshold)
      });
      setMessage('Notification settings updated successfully');
      setError('');
    } catch (error) {
      setError('Failed to update notification settings');
      setMessage('');
    }
  };

  return (
    <div>
      <h3>Credit Notification Settings</h3>
      <p>Get notified when your credits fall below a certain threshold.</p>
      {message && <p className="success">{message}</p>}
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Credit Threshold: </label>
          <input
            type="number"
            min="0"
            value={threshold}
            onChange={(e) => setThreshold(e.target.value)}
            placeholder="Enter credit threshold"
          />
          <p className="help-text">Leave empty to disable threshold notifications</p>
        </div>
        <button type="submit">Save Settings</button>
      </form>
    </div>
  );
}

export default NotificationSettings;
